<app-header></app-header>
<div class="main flex-center">
    <div class="container ">
        <div class="row d-block text-center">
            <div> <h2 class="playfair-bold96 color-black pd-115 pb-24 font-weight-bold ">What’s my story?</h2> </div>
            <p class="poppins-regular18 pb-56  w-736px text-left">
                I am senior product designer with more than 4 years of experience and worked with multiple teams to launch products and experience design. I loved to bring the product to life, and I able to contribute the design system to change the design and development process smoother, and I have been looking for multiple ways to make the process of each department more simplified. Apart from this, most of my time will spend with users survey and get to know my users better. I am always believed that speak with the real users to understand their pain points that I can deliver the best experience to them.
                <br><br>
                My professional focus is on web and mobile app product design, and has other skills in interaction design and minimalism animation that able to bring digital product design to another level.
                <br><br>
                Furthermore, get up! And I awake to looking forward things that I love to deal with day to day and non stop learning design. "Do What You Love, Love What You Do” 
            </p>
        </div> 
        <div class="row">
            <div class="col-12 col-sm-4 px-sm-3 px-0">
                <img class="w-100 pb-32" src="../../assets/img/story01.jpg" alt="">
            </div>
            <div class="col-12 col-sm-4 px-sm-3 px-0 ">
                <img class="w-100 pb-32" src="../../assets/img/story02.jpg" alt="">
            </div>
            <div class="col-12 col-sm-4 px-sm-3 px-0">
                <img class="w-100 pb-32" src="../../assets/img/story03.jpg" alt="">
            </div>
        </div>
        <div class="row d-block text-center">
            <div class="poppins-bold40 color-black pt-56 pb-24 poppins-font font-weight-bold w-736px text-left">Fast forward today</div>
            <p class="poppins-regular18 pb-56  w-736px text-left">
                Currently, I work as a Senior Product Designer at Carsome headquarter Malaysia with an amazing team building B2B car inspection application that help inspector life easy.
                <br><br>
                However, beside my full-time commitments. I work with few select freelance client base to create awesome products and solutions to help them growth.
                <br><br>
                I'm focus about designing thoughtful experience to ensure customers and users are satisfied when they're using your website or applications.
                <br><br>
                To sum up, I also like to documenting the user interface and user experience history and share it in the project case study. During the project, I always remember my process is a good thing.
            </p>
        </div> 
        <div class="row"> 
            <img class="w-100 " src="../../assets/img/story04.jpg" alt=""> 
        </div>
        <div class="row d-block text-center pb-115">
            <div class="poppins-bold40 color-black pt-56 pb-24 poppins-font font-weight-bold w-736px text-left">About design</div>
            <p class="poppins-regular18 pb-56  w-736px text-left">
                My goal is simple, imagine and build the best design delightful experiences and deliver excellent products of today and tomorrow on Web, Mobile Applications and SaaS Solution platform.
                <br><br>
                In my opinion, technology progresses things are getting more and more complex that are not friendly to selected users.
                <br><br>
                As a designer, I have to make these complex processes simple, delightful and joy to all users base everyday.
                <br><br>
                In case you would like to know more about my design background, my work or have questions, feel free to send me an email anytime! 
                <br><br>
                I'm ready to get in touch for any collaborations or opportunities✌
            </p>
            <button class="poppins-regular16 color-black bg-white story-resume  poppins-SemiBold"> <a class="title-black" href="../../assets/resume-animation/Z-Resume/Job Application_Product designer_LEE YONG TEE.pdf" target="_blank"> View resume</a></button>
            <button class="poppins-regular16 color-white bg-black story-case  poppins-SemiBold" [routerLink]="['/CaseStudies']">Case studies</button>
           
        </div> 
    </div>
</div>
<app-footer></app-footer>