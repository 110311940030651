<div class="main bg-black flex-center">
    <div class="container">
        <div class="row d-block text-center  w-736px">
            <h3 class="poppins-bold32 color-white pd-115 poppins-SemiBold">
                Think I can help? Let’s talk 💬
            </h3>
            <p class="poppins-bold14 color-grey pb-32">
                Always down to collaborate when I have the time. I’m hoping to further my career by disrupting the social product space. If you have a Website or Mobile app idea in mind or you’re just looking for advice and direction. Let’s schedule a virtual video call✌️
            </p> 
            <button class="poppins-regular16 color-black schedule bg-white mb-32 poppins-SemiBold "><a class="title-black" href="" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/yongtee/30min?&hide_gdpr_banner=1'});return false;">Let’s schedule!</a> </button>
            <br>
            <a class=" poppins-bold24 font-weight-bold" href="">ask@yongtee.com.my</a>
            <br>
            <div class="social-link ">
                <a  href="https://www.linkedin.com/in/yongtee/">LinkedIn</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                <a href="https://lottiefiles.com/yongtee">LottieFiles</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                <a href="https://www.behance.net/Yongtee">Behance</a>&nbsp;&nbsp;|&nbsp;&nbsp;
                <a href="https://dribbble.com/yongtee">Dribbble</a>&nbsp;&nbsp;|&nbsp;&nbsp; 
                <a href="https://www.instagram.com/yongteee/">Instagram</a> 
            </div>
        </div>
    </div>
</div> 
<hr >
<div class="main bg-black flex-center pt-24">
    <div class="container">
        <div class="row d-block text-center  pb-32 w-736px">
            <span class="color-grey02 poppins-regular12">
                Copyright © {{date}} Designed by YongTee. All rights reserved. 
                <br>
                South-East Asia | Malaysia | Kuala Lumpur
            </span>
        </div>
    </div>
</div> 