import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { CaseStudiesComponent } from './case-studies/case-studies.component';
import { MoneyLionComponent } from './case-studies/money-lion/money-lion.component';
import { FooterComponent } from './footer/footer.component';
import { StoryComponent } from './story/story.component';
import { ContactComponent } from './contact/contact.component';
import { BlogComponent } from './blog/blog.component';
const routes: Routes = [ 
  { path: '', component: MainComponent , data: { title: 'Product Designer | UI/UX | Website | Mobile App' } },
  { path: 'CaseStudies', component: CaseStudiesComponent , data: { title: 'Case Studies' }  },
  { path: 'CaseStudies/MoneyLion', component: MoneyLionComponent, data: { title: 'Case studies | MoneyLion' } },
  { path: 'footer', component: FooterComponent, data: { title: 'Case studies' } },
  { path: 'Story', component: StoryComponent, data: { title: 'Story' } },
  { path: 'Contact', component: ContactComponent, data: { title: 'Contact' } },
  // { path: 'Blog/Designarticle', component: BlogComponent, data: { title: 'Blog | Design article' } },
];
 
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
