import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  providers: [DatePipe]
})
export class FooterComponent implements OnInit {
  myDate = new Date();
  date: string;
  constructor(private datePipe: DatePipe) { 
    this.date = this.datePipe.transform(this.myDate, 'yyyy');
  }

  ngOnInit(): void {
    console.log('this.date',this.date)
  }

}
