<app-header></app-header>
<div class="main flex-center">
    <div class="container ">
        <div class="row d-block text-center">
            <div > <h2 class="playfair-bold96 color-black pd-115 pb-24 poppins-font font-weight-bold">Make a move?</h2> </div>
            <p class="poppins-regular18 pb-56  w-736px text-left">
                Thinking about a full redesign or a simple optimisation? Let’s have a free 15min chat about how I can make your website and mobile application work for you.
                <br><br>
                I design ethical websites and mobile application that inspire people to engage with your mission and invest their time, money and headspace into supporting the causes you care about.
                <br><br>
                During this quick virtual chat, I’ll share all the suggestions you need to unlock progress. We can discuss anything, including brand strategy, website optimisation and accessible design.
            </p>
        </div>
        <div class="row w-736px pb-115">
            
            <div class="col-12 col-sm-6 cursor-pointer"  onclick="Calendly.initPopupWidget({url: 'https://calendly.com/yongtee/30min?&hide_gdpr_banner=1'});return false;">
               <div class="contact-black">
                    <div class="poppins-bold32 color-white font-weight-bold pb-24 poppins-SemiBold">Web & Mobile Design</div>
                <p  class="poppins-regular16 pb-24  color-white ">
                    Start a project here if you have a great idea for a web or mobile app. This ideal for longer projects, such as design, prototyping and front-end development.
                </p>
                <p class="color-white poppins-regular16 font-weight-bold " > Start a project now    ></p>
               </div>
               
            </div>
            <div class="col-12 col-sm-6 cursor-pointer "  onclick="Calendly.initPopupWidget({url: 'https://calendly.com/yongtee/30min?&hide_gdpr_banner=1'});return false;">
                <div class="contact-white">
                    <div class="poppins-bold32 color-black font-weight-bold pb-24 poppins-SemiBold">General Consult & Opportunity</div>
                    <p  class="poppins-regular16 pb-24  color-black ">
                        Overview of your project? Revamp? Looking some feedback or just want to chat in general. Also, career opportunity that I might be fit? Find a good spot for 15-min quick call.
                    </p>
                    <p class="color-black poppins-regular16 font-weight-bold " > Let’s schedule now     ></p>
                </div>
            </div>
        </div> 
    </div>
</div> 
<app-footer></app-footer> 