<app-header></app-header>

<div class="main flex-center">
    <div class="container ">
        <div class="row d-block text-center">
            <div class="poppins-bold40 color-black pd-115 pb-24 poppins-font font-weight-bold">MoneyLion R&D for mobile app</div>
            <p class="poppins-regular18 pb-56  w-736px text-left">
                MoneyLion is a mobile banking and financial membership platform that empowers people to take control of their finances.
                <br><br>
                Since its launch in 2013, MoneyLion has engaged with 7.5 million hard-working Americans and has earned its members’ trust by building a full-service digital platform to deliver mobile banking, lending, and investment solutions.
                <br><br>
                From a single app, members can get a 360-degree snapshot of their financial lives and have access to personalized tips and tools to build and improve their credit and achieve everyday savings.
                <br><br>
                By rewarding financial responsibility, providing top-notch customer assistance and offering insightful financial education tips, we aim to positively impact our customers and enable them to better their financial health.
            </p>
        </div>
        <div class="row ">
            <div class="col-12 p-0">
                <img class="w-100 pb-56" src="../../assets/img/moneylion01.jpg" alt="moneylion">
                <div class="row d-block w-736px">
                    <div class="poppins-bold40 color-black pb-24 poppins-font font-weight-bold">Life working in MoneyLion R&D</div>
                    <p class="poppins-regular18 pb-56 ">
                        I joined MoneyLion in July 2019 as one of their first recruitment of R&D product designer. My responsibility is main focus on core “Research & Development, Earn Rewards Pod” and execute the idea from our stakeholder and make it into reality for our product. Beside that, helping the others core product pod whenever necessary. 
                        <br><br>
                        Since then, I have contributed successful product designs and put them into production. Obviously, when the product fails, I have learned and accumulated a lot of experience. I believe that I always learn from my mistakes and gain my knowledge.
                    </p>
                </div>
                <img class="w-100 pb-56" src="../../assets/img/moneylion02.jpg" alt="moneylion">
                <div class="row d-block w-736px">
                    <div class="poppins-bold40 color-black pb-24 poppins-font font-weight-bold">Problem statement</div>
                    <p class="poppins-regular18 pb-56 ">
                        Our stakeholder wish to benefits more rewards to our customer. Customer with the physical or virtual debit card for your purchases. Ideally, you’ll get a notification that you’ve earned cashback reward opportunity after every purchase of $10 or more!
                        <br><br>
                        With RoarMoney, you can earn cashback on every purchase, every day. And it’s not your basic cashback. This is cashback that grows! We’ll invest it for you in a fully managed portfolio, where you can grab it or let us grow it. Maybe we should call it cash future.
                    </p>
                    <div class="poppins-bold40 color-black pb-24 poppins-font font-weight-bold">Understanding the users</div>
                    <p class="poppins-regular18 pb-56 ">
                        In this project I identified people who use our physical and virtual card frequently and are already users of Rewards so they would be familiar with the functionality that already exists and have an ideas for additional feature. Therefore, I will implement in-app upsell screen and electronic direct mail marketing to notify users.
                    </p>
                    <div class="poppins-bold40 color-black pb-24 poppins-font font-weight-bold">My role</div>
                    <p class="poppins-regular18 pb-56 ">
                        I was responsible for all aspects of the Rewards project from requirements gathering, user flow, animation research coordination, story board and research a new lightweight in scable for our LottieFiles animation.
                        <br><br>
                        I collaborated with product manager, developers and quality assurance engineer to manage the scope, collect feedback, facilitate animation testing and sharing new research and development in LottieFiles knowledge to the team.
                    </p>
                </div>
            </div> 
        </div> 
    </div>
</div>
<div class="main moneylion-bg flex-center">
    <div class="container ">
        <div class="row d-block  text-center">
            <div class="playfair-bold36 color-black pt-56 pb-24 poppins-font font-weight-bold">Three month project timeline</div>
            
        </div>
        <div class="row text-center">
            <div class="col-12 col-sm-3">
                <img src="../../../assets/img/icon/timeline01.svg" alt="">
                <div class="font-weight-bold poppins-bold16 p-1612" >Month 1 • Understand</div>
                <p class="poppins-bold16 color-black03 font-italic">The industry landscape, structure, problem and design</p>
                <ul class="text-left poppins-bold16 timeline-ul">
                    <li>Identify comparable apps</li>
                    <li>Complete feature analysis</li>
                    <li>Interview with product owner</li>
                    <li>MoneyLion design system</li>
                    <li>LottieFiles research</li>
                    <li> Document current and future state process information architecture</li>
                </ul>
            </div> 
            <div class="col-12 col-sm-3">
                <img src="../../../assets/img/icon/timeline02.svg" alt="">
                <div class="font-weight-bold poppins-bold16 p-1612" >Month 2 • Design</div>
                <p class="poppins-bold16 color-black03 font-italic">Demonstrate how design could easily request and share</p>
                <ul class="text-left poppins-bold16 timeline-ul">
                    <li>Brainstorm with manager</li>
                    <li>Create sketch</li>
                    <li>Initial screen design feedback</li>
                    <li>Research animation art style</li>
                    <li>Storyboard</li>
                    <li>Define essential metadata</li>
                    <li>Design and plan MVP screen</li>
                    <li>Revise screen and animation</li>
                </ul>
            </div>
            <div class="col-12 col-sm-3">
                <img src="../../../assets/img/icon/timeline03.svg" alt="">
                <div class="font-weight-bold poppins-bold16 p-1612" >Month 3 • Development & UAT</div>
                <p class="poppins-bold16 color-black03 font-italic">Facilitate usability testing to validate design decisions</p>
                <ul class="text-left poppins-bold16 timeline-ul">
                    <li>Execute testing</li>
                    <li>Identify improvements feature and scale for next step</li>
                    <li>Roll-out test animation for iOS and Android device use cases</li>
                    <li>Implement bug fix for json format, re-generate with different LottieFiles plugin  </li>
                </ul>
            </div> 
            <div class="col-12 col-sm-3">
                <img src="../../../assets/img/icon/timeline04.svg" alt="">
                <div class="font-weight-bold poppins-bold16 p-1612" >Month 4.5 • Live and future step</div>
                <p class="poppins-bold16 color-black03 font-italic">Provide support to other team and documentation LottieFiles</p>
                <ul class="text-left poppins-bold16 timeline-ul">
                    <li>Monitor production version </li>
                    <li>Provide guidance to other product pod for replacement of GIF and transform to LottieFiles</li>
                    <li>Extra boost multiplier cashback</li>
                </ul>
            </div>  
            <p class="middle-line poppins-regular16 color-black03 "><span> Manage stakeholder and product owner expectations</span></p>
        </div>
    </div>
</div>
<div class="main flex-center">
    <div class="container ">
        <div class="row d-block w-736px">
            <div class="poppins-bold40 color-black pt-56 pb-24 poppins-font font-weight-bold">Breaking down the process - Understand</div>
            <p class="poppins-regular18 pb-56  w-736px text-left">
                First, MoneyLion banking products are located in New York City. However, I am in Malaysia. Therefore, I need to understand the company's products and use one of the online tool research methods, a well-known comparison tool called finder, to compare the financial technology banking industry. In addition, I need to filter it by closer competitors that suit us. After finding the information, I talked to the product owner to learn more about the industry. Below is the following research chart I found.
            </p>
        </div>
    </div>
</div>
<div class="main flex-center">
    <div class="container ">
        <div class="row d-block w-736px">
            <div class="poppins-bold40 color-black pt-56 pb-24 poppins-font font-weight-bold">The LottieFiles research</div>
            <p class="poppins-regular18 pb-56  w-736px text-left">
                First of all, I get to know those well known competitor from the product owner and do a background check to understand the complete feature analysis within my circle of competence. After that, connect with manager to understand how design department structure and align the branding itself. 
                <br><br>
                In addition, I need to pickup the initiative to learn about LottieFiles. Since we are facing Appstore and Playstore file size reducing on graphic issue. To sum up, spending most of the time deal with decrease graphic sizing with developer. I decided and make the initiative to explore with LottieFiles. Lastly, document the current and future state process information architecture for our current mobile app as I need a big picture of what MoneyLion did and benefit to users.
                <br><br>
                To sum up, LottieFiles is an open source animation file format that’s tiny, high quality, interactive, and can be manipulated at runtime. The top 500 apps on the App store now use Lottie to engage users and enhance conversions.
            </p>
        </div>
        <img class="w-100" src="../../../assets/img/moneylion03.jpg" alt="">
        <div class="row d-block w-736px">
            <div class="poppins-bold40 color-black pt-56 pb-24 poppins-font font-weight-bold">Mapping the product in each pod</div>
            <p class="poppins-regular18 pb-56  w-736px text-left">
                After two weeks of on-boarding experience, I have a general understanding of the overall situation of the company's various departments. When designing, I reviewed all product pods and external applications to understand the use of product-specific and industry standards. I found that the current mobile application information architecture documentation is missing. I created it based on my own understanding and can share it with new entrants who may work with designers.
            </p>
        </div>
        <img class="w-100" src="../../../assets/img/moneylion04.jpg" alt="">
        <div class="row d-block w-736px">
            <div class="poppins-bold40 color-black pt-56 pb-24 poppins-font font-weight-bold">Brainstorming - How might we</div>
            <p class="poppins-regular18 pb-56  w-736px text-left">
                First of all, I have conducted a brainstorming meeting with my manager to generate as many ideas or solutions or problems as possible. I always think that the “How Might We” (HMW) process could provide designers with a clear direction. During this brainstorm session, and with this process the designer could have an idea to planning a proper user journey map.
            </p>
        </div>
        <img class="w-100" src="../../../assets/img/moneylion05.jpg" alt="">
        <div class="row d-block w-736px">
            <div class="poppins-bold40 color-black pt-56 pb-24 poppins-font font-weight-bold">User flow concept</div>
            <p class="poppins-regular18 pb-56  w-736px text-left">
                When creating this user flow, due to different user operating conditions, I divided it into three pillars.
                <br><br>
                The first stage is to verify the user’s purchase status. If users wish to active this, they need to spend more than $10 or more to get this reward. After that, due to the high load, the designer needs to consider what to do if it is not possible to view or proceed to the next step, and how the designer should deal with this situation. Although we have a standard error endpoint to handle server issues. The communication between engineers and designers is always the first priority.
                <br><br>
                In the second stage, users are more inclined to trigger actions and activate animated stories of success and failure. Finally, based on our random artificial intelligence algorithm, few users can get higher bonuses.
            </p>
        </div>
        <img class="w-100" src="../../../assets/img/moneylion06.jpg" alt="">
        <div class="row d-block w-736px">
            <div class="poppins-bold40 color-black pt-56 pb-24 poppins-font font-weight-bold">Sketching main screen idea</div>
            <p class="poppins-regular18 pb-56  w-736px text-left">
                Because the design system is well set up and in line with our brand marketing direction. I can quickly have a set of components to play with, and try different sketch ideas based on the component references. In addition, it is a great time to present this idea during the scrum sprint meeting weekly,
            </p>
        </div>
        <img class="w-100" src="../../../assets/img/moneylion07.jpg" alt="">
        <div class="row d-block w-736px">
            <div class="poppins-bold40 color-black pt-56 pb-24 poppins-font font-weight-bold">Animation storyboard and final screen</div>
            <p class="poppins-regular18 pb-56  w-736px text-left">
                As mentioned earlier, there are many scenarios in which users are more inclined to trigger operations. Therefore, I need to prepare multiple sets of animation stories and certain loop animations.
                <br><br>
                However, I faced a huge challenge when exporting to json format. Because each platform accepts different readings of different frames per second. Designers need to carefully set on adobe after effect. And to study what features are currently supported by the LottieFiles plug-in, which is why proper research and development is needed.
                <br><br>
                Secondly, I am very happy to work with an excellent development team, we desired for success and continue to try each export format and test run in the UAT session. Our goal is simple. We want to maintain the best quality and display it on a variety of smoothly running platforms to provide a good user experience.
                <br><br>
                Lastly, I attached the userflow below for references purpose.
            </p>
        </div>
        
    </div>
</div> 
<div class="main bg-grey03 ptb-48 flex-center">
    <div class="container ">
        <img class="w-100" src="../../../assets/img/moneylion08.jpg" alt="">
    </div>
</div> 
<div class="main flex-center">
    <div class="container ">
        <div class="row d-block w-736px">
            <div class="poppins-bold40 color-black pt-56 pb-24 poppins-font font-weight-bold">Development & UAT</div>
            <p class="poppins-regular18 pb-56  w-736px text-left">
                I need to make sure that LottieFiles animation allows users from the different device operating systems to have a smooth experience when browsing our applications. I need to test various platforms, including mobile application websites. In addition, work closely with developers to guide the animation display sequence based on the user's story.
                <br><br>
                Finally, prepare a list of bug fixes in Google document for json format that using After effect to generated from the LottieFiles or Bodymovin plug-in engine. The purpose is to guide the next designer to avoid certain feature output formats.
            </p>
        </div>
        <img class="w-100" src="../../../assets/img/moneylion09.jpg" alt="">
        <div class="row d-block w-736px">
            <div class="poppins-bold40 color-black pt-56 pb-24 poppins-font font-weight-bold">Go live and future step</div>
            <p class="poppins-regular18 pb-56  w-736px text-left">
                Our future plans for Reward pod Shake'N' Bank will continue to use our debit card to convert users in large numbers. After launching, we can study from the amplitude chart that our indicator still shows that a large number of users are slowly using this feature.
                <br><br>
                Maintain and track this feature and make sure how we can extend this feature to another level. For example, enable additional boost multiplier cashback for a small number of eligible users.
                <br><br>
                Finally, my ultimate goal of this reward pod project and research and development is to guide other product pods to smoothly replace our current GIF animations converted to LottieFiles format.
            </p>
        </div>
        <div class="row">
            <div class="col-12 col-sm-3">
                <lottie-player src="../../../assets/resume-animation/SnB/SnB_Hand.json"  background="transparent"  speed="1" loop autoplay></lottie-player>
            </div> 
            <div class="col-12 col-sm-3">
                <lottie-player src="../../../assets/resume-animation/SnB/SnB_Fail.json"  background="transparent"  speed="1" loop autoplay></lottie-player>
            </div>
            <div class="col-12 col-sm-3">
                <lottie-player src="../../../assets/resume-animation/SnB/SnB_Success.json"  background="transparent"  speed="1" loop autoplay></lottie-player>
            </div>
            <div class="col-12 col-sm-3"> 
                <lottie-player src="../../../assets/resume-animation/SnB/SnB_Multiplier.json"  background="transparent"  speed="1" loop autoplay></lottie-player>
            </div>
        </div>
        <div class="row d-block w-736px">
            <div class="poppins-bold40 color-black pt-56 pb-24 poppins-font font-weight-bold">Nevertheless, LottieFiles applied to other product pods as well</div>
            <p class="poppins-regular18 pb-56  w-736px text-left">
                While scale up the animation, there’s a documentation needed for future references. For example below,
                <br>
                • Write a short summary for this animation purpose.
                <br>
                • Public access link on LottieFiles preview invited by figma hyperlink.
                <br>
                • Designer access link on Dropbox.
                <br>
                • Generated by “X Designer Name” and plugin version (Ex. LottieFiles v0.1.20(5.5.7).
                <br>
                • Creator by “X Designer name”.
            </p>
        </div>
        <div class="row mb-32"> 
            <div class="col-12 col-sm-3">
                <lottie-player src="../../../assets/resume-animation/Row_1/Row_1_Global.json"  background="transparent"  speed="1" loop autoplay></lottie-player>
            </div>
            <div class="col-12 col-sm-3">
                <lottie-player src="../../../assets/resume-animation/Row_1/Row_1_BV.json"  background="transparent"  speed="1" loop autoplay></lottie-player>
            </div>
            <div class="col-12 col-sm-3">
                <lottie-player src="../../../assets/resume-animation/Row_1/Row_1_Instacash.json"  background="transparent"  speed="1" loop autoplay></lottie-player>
            </div>
            <div class="col-12 col-sm-3">
                <lottie-player src="../../../assets/resume-animation/Row_1/Row_1_Passcode.json"  background="transparent"  speed="1" loop autoplay></lottie-player>
            </div>
        </div>
        <div class="row pb-56">
            <div class="col-12 col-sm-3">
                <lottie-player src="../../../assets/resume-animation/Row_2/Row_2_Onboarding.json"  background="transparent"  speed="1" loop autoplay></lottie-player> 
            </div>
            <div class="col-12 col-sm-3">
                <lottie-player src="../../../assets/resume-animation/Row_2/Row_2_Lion.json"  background="transparent"  speed="1" loop autoplay></lottie-player> 
            </div>
            
            <div class="col-12 col-sm-3">
                <lottie-player src="../../../assets/resume-animation/Row_2/Row_2_Instacash.json"  background="transparent"  speed="1" loop autoplay></lottie-player> 
            </div>
            <div class="col-12 col-sm-3">
                <lottie-player src="../../../assets/resume-animation/Row_2/Row_2_Christmas.json"  background="transparent"  speed="1" loop autoplay></lottie-player> 
            </div>
        </div>
    </div>
</div> 
<div class="main moneylion-bg flex-center">
    <div class="container ">
        <div class="row d-block  text-center">
            <h2 class="playfair-bold36 color-black pt-56 pb-24 font-weight-bold">
                “I like it when I see my design result and get a better sense of how my time was spent.”
            </h2>
            <p class="poppins-regular18 font-italic pb-56">and what I could have done better in future.</p>
        </div> 
    </div>
</div>
<div class="main flex-center">
    <div class="container ">
        <div class="row d-block w-736px">
            <div class="poppins-bold40 color-black pt-56 pb-24 poppins-font font-weight-bold">Lesson learned</div>
            <p class="poppins-regular18 pb-56  w-736px text-left">
                Product design is a team effort. Every pod team member is able to give feedback, and feedback is crucial in helping us spot aspects of the product that designer not have considered. It's very important to share our design work early. I have, in the past, been reluctant to share my work in its early stages before I was entirely confident with it. But now I realized how valuable it is to share. The process helps me correct mistakes before I move to team or stakeholder  presentation in the wrong direction and time wasted. It also keeps me from getting too attached to my own design and makes me willing to accept good design feedback as I iterate.
                <br><br>
                I learned that there is no perfect design solution. The only way to achieve design goals and improve our products is through iteration and consolidation of user feedback. Each iteration allows me to focus on the problems I can solve. The critical opinions of the design manager allowed me to look at the problem from different directions and helped shape better products.
                <br><br>
                Lastly, the most interesting part is to explore the feasibility test of LottieFiles animation with the technical team to distinguish each layer design and plug-in converter version to run. The fun of the team is to be curious and eager to succeed. Everyone has a CAN DO attitude.
                <br><br>
                I really enjoyed the process from ideation, design, testing to development of this project. Couldn't have done so without the amazing team at MoneyLion!
                <br><br>
                Thank you so much for your patience and reading until the end.
            </p>
        </div> 
    </div>
</div>
<app-footer></app-footer>