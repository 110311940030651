<app-header></app-header>
<div class="main flex-center">
    <div class="container ">
        <div class="row d-block text-center">
            <div> <h2 class="playfair-bold96 color-black pd-115 pb-24 poppins-font font-weight-bold">Design articles</h2> </div>
            <p class="poppins-regular18 pb-56  w-736px text-left">
                Discover how I think behind the scene look at my design process. This articles about Design, Business, Digital Marketing and Productivity. In here, you'll find articles and case studies as well as UX/UI & branding tips you can implement easily.
            </p>
        </div>
        <div class="row pb-115"> 
            <div class="col-12 col-sm-6 flex-center p-0">
                <div>
                    <p class="poppins-regular12 color-grey02 p-1612">28 March 2021 • 5 min read</p> 
                    <h3 class="poppins-bold24 font-weight-bold poppins-SemiBold">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In leo. Maximum three line...
                    </h3>
                    <p class="poppins-regular16 px-sm-0 px-3 pb-sm-0 pb-4">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus volutpat in nunc, odio phasellus. Placerat tortor viverra massa integer vitae donec quis scelerisque quisue maxim... 
                    </p>
                </div>
            </div>
            <div class="col-12 col-sm-6 p-0">
                <img class="w-100" src="../../assets/img/blog01.jpg" alt="moneylion-app">
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-4 pb-56 px-sm-3 px-0 ">
                <img class="w-100" src="../../assets/img/blog02.jpg" alt="">
                <p class="poppins-regular12 color-grey02 p-1612">28 March 2021 • 5 min read</p> 
                <h3 class="poppins-bold24 font-weight-bold poppins-SemiBold">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In leo. Maximum three line...
                </h3>
                <p class="poppins-regular16  px-sm-0 px-3">
                    Overview of your project? Revamp? Looking some feedback or just want to chat in general. ffso, career c maximum three line...
                </p>
            </div>
            <div class="col-12 col-sm-4 pb-56 px-sm-3 px-0">
                <img class="w-100" src="../../assets/img/blog02.jpg" alt="">
                <p class="poppins-regular12 color-grey02 p-1612">28 March 2021 • 5 min read</p> 
                <h3 class="poppins-bold24 font-weight-bold poppins-SemiBold">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In leo. Maximum three line...
                </h3>
                <p class="poppins-regular16  px-sm-0 px-3">
                    Overview of your project? Revamp? Looking some feedback or just want to chat in general. ffso, career c maximum three line...
                </p>
            </div>
            <div class="col-12 col-sm-4 pb-56 px-sm-3 px-0">
                <img class="w-100" src="../../assets/img/blog02.jpg" alt="">
                <p class="poppins-regular12 color-grey02 p-1612">28 March 2021 • 5 min read</p> 
                <h3 class="poppins-bold24 font-weight-bold poppins-SemiBold">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In leo. Maximum three line...
                </h3>
                <p class="poppins-regular16  px-sm-0 px-3">
                    Overview of your project? Revamp? Looking some feedback or just want to chat in general. ffso, career c maximum three line...
                </p>
            </div> 
        </div>
        <div class="row">
            <div class="col-12 col-sm-4 pb-56 px-sm-3 px-0 ">
                <img class="w-100" src="../../assets/img/blog02.jpg" alt="">
                <p class="poppins-regular12 color-grey02 p-1612">28 March 2021 • 5 min read</p> 
                <h3 class="poppins-bold24 font-weight-bold poppins-SemiBold">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In leo. Maximum three line...
                </h3>
                <p class="poppins-regular16  px-sm-0 px-3">
                    Overview of your project? Revamp? Looking some feedback or just want to chat in general. ffso, career c maximum three line...
                </p>
            </div>
            <div class="col-12 col-sm-4 pb-56 px-sm-3 px-0">
                <img class="w-100" src="../../assets/img/blog02.jpg" alt="">
                <p class="poppins-regular12 color-grey02 p-1612">28 March 2021 • 5 min read</p> 
                <h3 class="poppins-bold24 font-weight-bold poppins-SemiBold">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In leo. Maximum three line...
                </h3>
                <p class="poppins-regular16  px-sm-0 px-3">
                    Overview of your project? Revamp? Looking some feedback or just want to chat in general. ffso, career c maximum three line...
                </p>
            </div>
            <div class="col-12 col-sm-4 pb-56 px-sm-3 px-0">
                <img class="w-100" src="../../assets/img/blog02.jpg" alt="">
                <p class="poppins-regular12 color-grey02 p-1612">28 March 2021 • 5 min read</p> 
                <h3 class="poppins-bold24 font-weight-bold poppins-SemiBold">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In leo. Maximum three line...
                </h3>
                <p class="poppins-regular16  px-sm-0 px-3">
                    Overview of your project? Revamp? Looking some feedback or just want to chat in general. ffso, career c maximum three line...
                </p>
            </div> 
        </div>
    </div>
</div>
<app-footer></app-footer>