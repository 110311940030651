<!-- <div class="container header"> 
    <nav class="navbar navbar-expand-lg navbar-light ">
      <img src="./assets/img/icon/YT.png" alt="">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" href="#">Case studies</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Story</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Resume</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Contact</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Blog</a>
          </li> 
        </ul> 
        <div class="info">
          <span>ask@yongtee.com</span>
          <p class="phone-number"> 🇲🇾(601) 4360 0845 📞</p>
          <div class="social-media">
            <a href="https://www.linkedin.com/in/yongtee/"><img src="./assets/img/icon/linkedin.png" alt="linkedin"></a> 
            <a href="https://lottiefiles.com/yongtee"><img src="./assets/img/icon/lottiefiles.png" alt="lottiefiles"></a>
            <a href="https://www.behance.net/Yongtee"><img src="./assets/img/icon/behance.png" alt="behance"></a>
            <a href="https://dribbble.com/yongtee"><img src="./assets/img/icon/dribbble.png" alt="dribbble"></a>
            <a href="https://www.instagram.com/yongteee/"><img src="./assets/img/icon/insta.png" alt="instagram"></a>
          </div>
        </div>
      </div>
    </nav>
  </div> -->
<div class="header">
  <div class="container"> 
    <nav ngxNavbarDynamicExpand class="navbar-light ">
      <img class="cursor-pointer"  [routerLink]="['']" src="./assets/img/icon/YT.svg" alt="">
      <button class="navbar-toggler" type="button" (click)="collapse.toggle()" aria-controls="main-nav"
        aria-expanded="false" > 
        <span class="navbar-toggler-icon d-flex align-items-center justify-content-center" > </span>
      </button>
      <ngx-navbar-collapse id="main-nav" #collapse="ngxNavbarCollapse" >
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link poppins-regular14 text-center poppins-SemiBold" [routerLink]="['/CaseStudies']" [routerLinkActive]="'active'"  >Case studies</a>
          </li>
          <li class="nav-item">
            <a class="nav-link poppins-regular14 text-center poppins-SemiBold" [routerLink]="['/Story']" [routerLinkActive]="'active'">Story</a>
          </li>
          <li class="nav-item">
            <a class="nav-link poppins-regular14 text-center poppins-SemiBold" href="../../assets/resume-animation/Z-Resume/Job Application_Product designer_LEE YONG TEE.pdf" target="_blank">Resume</a>
          </li>
          <li class="nav-item">
            <a class="nav-link poppins-regular14 text-center poppins-SemiBold" [routerLink]="['/Contact']"  [routerLinkActive]="'active'" >Contact</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link poppins-regular14 text-center poppins-SemiBold" [routerLink]="['/Blog/Designarticle']"  [routerLinkActive]="'active'" >Blog</a>
          </li>  -->
        </ul>
        <div class="info">
          <span class="poppins-bold20 blue font-weight-bold text-center">ask@yongtee.com</span>
          <p class="phone-number poppins-regular12 font-weight-bold"> 🇲🇾(601) 4360 0845 📞</p>
          <div class="social-media flex-center">
            <a href="https://www.linkedin.com/in/yongtee/"><img src="./assets/img/icon/linkedin.svg" alt="linkedin"></a> 
            <a href="https://lottiefiles.com/yongtee"><img src="./assets/img/icon/lottiefiles.svg" alt="lottiefiles"></a>
            <a href="https://www.behance.net/Yongtee"><img src="./assets/img/icon/behance.svg" alt="behance"></a>
            <a href="https://dribbble.com/yongtee"><img src="./assets/img/icon/dribbble.svg" alt="dribbble"></a>
            <a href="https://www.instagram.com/yongteee/"><img src="./assets/img/icon/insta.svg" alt="instagram"></a>
          </div>
        </div>
      </ngx-navbar-collapse>
    </nav>
  </div>
</div>