import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { MainComponent } from './main/main.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxNavbarModule } from 'ngx-bootstrap-navbar';
import { CaseStudiesComponent } from './case-studies/case-studies.component';
import { MoneyLionComponent } from './case-studies/money-lion/money-lion.component';
import { FooterComponent } from './footer/footer.component';
import { StoryComponent } from './story/story.component';
import { ContactComponent } from './contact/contact.component';
import { BlogComponent } from './blog/blog.component'; 
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MainComponent,
    CaseStudiesComponent,
    MoneyLionComponent,
    FooterComponent,
    StoryComponent,
    ContactComponent,
    BlogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxNavbarModule
  ], 
  providers: [],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
