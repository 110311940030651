<app-header></app-header>

<div class="main height100 flex-center">
    <div class="container ">
        <div class="row d-block text-center">
            <h2 class="playfair-bold96 mb-32 title-black">Hey, I am Yong Tee 👋</h2> 
            <p class="poppins-regular18 pb-32">
                <span class="font-weight-bold font-italic">“Product Designer passionate about createing product that deliver 
                <br>
                impactful users experiences.” </span>
                <br>
                -
                <br>
                Currently working at <a class="font-weight-bold" href="https://www.carsome.my/">Carsome Malaysia</a>
            </p>
            <button class="poppins-regular16 bg-black" > <a class="color-white" [routerLink]="['/Story']" [routerLinkActive]="'active'">Read my story</a></button>
        </div>
    </div>
</div> 
<div class="main bg-black flex-center">
    <div class="container">
        <div class="row d-block text-center ">
            <h2 class="playfair-bold44 color-white pd-115">Over these years, I’ve designed several digital leading products </h2>
            <p class="poppins-regular18 color-grey pb-56">
                with big companies and startups
            </p>
        </div>
        <div class="row company-details pb-115">
            <div class="col-12 col-sm-4">
                <a href="https://www.carsome.my/" target="_blank"><h3 class="yellow poppins-bold32 font-weight-bold">Carsome</h3></a> 
                <span class="poppins-regular18 color-grey02 font-weight-bold p-0">Senior product designer</span>
                <p class="poppins-regular16 color-grey p-0">
                    Currently building car inspector application experiences to improved B2B across carsome products.
                    <br><br>
                    The goal. Making selling car platform through app from car inspection, ownership transfer, financing become hassle-free also eliminates the pain points in traditional.
                </p>
            </div>
            <div class="col-12 col-sm-4">
                <a href="https://www.moneylion.com/" target="_blank"><h3 class="green poppins-bold32 font-weight-bold">MoneyLion</h3></a> 
                <span class="poppins-regular18 color-grey02 font-weight-bold p-0">Product designer</span>
                <p class="poppins-regular16 color-grey p-0">
                    Previously, focus in R&D for current products and demonstrate capabilities, value proposition and alignment to strategy.
                    <br><br>
                    The goal. Rewire the American banking system so that we can positively change the financial path for every hard-working American.
                </p>
            </div>
            <div class="col-12 col-sm-4">
                <a href="https://www.leisue.com/" target="_blank"><h3 class="blue poppins-bold32 font-weight-bold">Star Systems</h3></a> 
                <span class="poppins-regular18 color-grey02 font-weight-bold p-0">Senior UI/UX designer</span>
                <p class="poppins-regular16 color-grey p-0">
                    Previously, building a real-time chat messenger widget that help you to deliver effortless customer support across channels.
                    <br><br>
                    The goal. TalkM brings great opportunities to your business. Deliver powerful solution that understands customers need and help your company grow and succeed.
                </p>
            </div>
        </div>
    </div>
</div> 
<div class="main flex-center">
    <div class="container ">
        <div class="row d-block w-736px">
            <h2 class="playfair-bold44 color-black pd-115">Design and scale products from idea to reality</h2>
            <p class="poppins-regular18 pb-56">
                and translate stakeholder ideas into design and flow planning
            </p>
        </div>
        <div class="row pb-115">
            <div class="col-12 col-sm-6 p-0">
                <img class="w-100" src="../../assets/img/moneylion-app.jpg" alt="moneylion-app">
            </div>
            <div class="col-12 col-sm-6 flex-center">
                <div class="pl-60">
                    <h4 class="poppins-bold24 font-weight-bold pt-32 plr-0">MoneyLion app</h4>
                    <p class="poppins-regular16 pb-32">
                        Rewire the American banking system so that we can positively change the financial path for every hard-working American. The best product we have “Core Membership, Credit Builder Plus, Instacash, Manage Investment, Earn Rewards and Credit Score Tracking”.
                        Where the 99% can feel 100% about their future.
                    </p>
                    <button class="poppins-regular16 color-white bg-black" [routerLink]="['/CaseStudies']"> Full case studies</button>
                </div>
            </div>
        </div>
        <div class="row pb-115">
            <div class="col-12 col-sm-6 p-0">
                <img class="w-100" src="../../assets/img/carsome-inspection-app.jpg" alt="Carsome-inspection-app">
            </div>
            <div class="col-12 col-sm-6 flex-center">
                <div class="pl-60">
                    <h4 class="poppins-bold24 font-weight-bold pt-32 plr-0">Carsome inspection app</h4>
                    <p class="poppins-regular16 pb-32">
                        More case studies are coming soon! Please stay tuned and it will available soon.
                    </p>
                    <button class="poppins-regular16 color-grey02 bg-black">Coming soon</button>
                </div>
            </div>
        </div>
        <div class="row pb-115">
            <div class="col-12 col-sm-6 p-0 ">
                <img class="w-100" src="../../assets/img/carsome-design-system.jpg" alt="carsome-design-system">
            </div>
            <div class="col-12 col-sm-6 flex-center">
                <div class="pl-60">
                    <h4 class="poppins-bold24 font-weight-bold pt-32 plr-0">Carsome design system</h4>
                    <p class="poppins-regular16 pb-32">
                        More case studies are coming soon! Please stay tuned and it will available soon.
                    </p>
                    <button class="poppins-regular16 color-grey02 bg-black">Coming soon</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="main flex-center" id="readMyStory">
    <div class="container">
        <div class="row d-block text-center pb-115">
            <h2 class="playfair-bold44 pd-115">
                Ready to get started? <br>
                Let’s talk about your idea</h2>
            <p class="poppins-regular18 pb-32">
                I was expecting is you👋 You know about me, let’s make something big together?
            </p>
            <button class="poppins-regular16 color-black bg-white resume"> <a class="color-black" href="../../assets/resume-animation/Z-Resume/Job Application_Product designer_LEE YONG TEE.pdf" target="_blank"> View resume</a></button>
            <button class="poppins-regular16 color-white bg-black contact" [routerLink]="['/Contact']">Contact</button>
        </div>
    </div>
</div> 
<!-- <div class="main flex-center">
    <div class="container ">
        <div class="row d-block w-736px">
            <h2 class="playfair-bold44 color-black pd-115">I write about product design and technology</h2>
            <p class="poppins-regular18 pb-56">
                Knowledge shared is about how I understand it and a great learning process
            </p>
        </div> 
        <div class="row">
            <div class="col-12 col-sm-4 px-sm-3 px-0">
                <img class="w-100" src="../../assets/img/img01.jpg" alt="">
                <p class="poppins-regular12 color-grey02 p-1612">28 March 2021 • 5 min read</p> 
                <h3 class="poppins-bold24 font-weight-bold poppins-SemiBold">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In leo. Maximum three line...
                </h3>
                <p class="poppins-regular16 px-sm-0 px-3 pb-sm-0 pb-4">
                    Overview of your project? Revamp? Looking some feedback or just want to chat in general. ffso, career c maximum three line...
                </p>
            </div>
            <div class="col-12 col-sm-4 px-sm-3 px-0">
                <img class="w-100" src="../../assets/img/img02.jpg" alt="">
                <p class="poppins-regular12 color-grey02 p-1612">28 March 2021 • 5 min read</p> 
                <h3 class="poppins-bold24 font-weight-bold poppins-SemiBold">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In leo. Maximum three line...
                </h3>
                <p class="poppins-regular16 px-sm-0 px-3 pb-sm-0 pb-4">
                    Overview of your project? Revamp? Looking some feedback or just want to chat in general. ffso, career c maximum three line...
                </p>
            </div>
            <div class="col-12 col-sm-4 px-sm-3 px-0">
                <img class="w-100" src="../../assets/img/img03.jpg" alt="">
                <p class="poppins-regular12 color-grey02 p-1612">28 March 2021 • 5 min read</p> 
                <h3 class="poppins-bold24 font-weight-bold poppins-SemiBold">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In leo. Maximum three line...
                </h3>
                <p class="poppins-regular16 px-sm-0 px-3 ">
                    Overview of your project? Revamp? Looking some feedback or just want to chat in general. ffso, career c maximum three line...
                </p>
            </div> 
        </div>
        <button  class="poppins-regular16 color-white bg-black more-post ">More post</button>
    </div>
</div>  -->
<app-footer></app-footer>
