<app-header></app-header> 
<div class="main flex-center">
    <div class="container ">
        <div class="row d-block text-center">
            <h2 class="playfair-bold96 color-black pd-115 ">Design and Crafted By YongTee</h2>
            <p class="poppins-regular18 pb-56">
                The following works are after my careful selection of the experience, process, effort, tears, and sweat accumulated over the past few years.
            </p>
        </div>
        <div class="row pb-115">
            <div class="col-12 col-sm-6 p-0">
                <img class="w-100" src="../../assets/img/moneylion-app.jpg" alt="moneylion-app">
            </div>
            <div class="col-12 col-sm-6 flex-center">
                <div class="pl-60">
                    <h4 class="poppins-bold24 font-weight-bold pt-32 plr-0">MoneyLion app</h4>
                    <p class="poppins-regular16  p-0 pb-sm-0 pb-24">
                        Rewire the American banking system so that we can positively change the financial path for every hard-working American. The best product we have “Core Membership, Credit Builder Plus, Instacash, Manage Investment, Earn Rewards and Credit Score Tracking”.
                        Where the 99% can feel 100% about their future.
                    </p> 
                    <button class="poppins-regular16 color-white bg-black" [routerLink]="['/CaseStudies/MoneyLion']" >Full case studies</button>
                </div>
            </div>
        </div>
        <div class="row pb-115">
            <div class="col-12 col-sm-6 p-0">
                <img class="w-100" src="../../assets/img/carsome-inspection-app.jpg" alt="Carsome-inspection-app">
            </div>
            <div class="col-12 col-sm-6 flex-center">
                <div class="pl-60">
                    <h4 class="poppins-bold24 font-weight-bold pt-32 plr-0">Carsome inspection app</h4>
                    <p class="poppins-regular16  p-0 pb-sm-0 pb-24">
                        More case studies are coming soon! Please stay tuned and it will available soon.
                    </p>
                    <button class="poppins-regular16 color-grey02 bg-black">Coming soon</button>
                </div>
            </div>
        </div>
        <div class="row pb-115">
            <div class="col-12 col-sm-6 p-0 ">
                <img class="w-100" src="../../assets/img/carsome-design-system.jpg" alt="carsome-design-system">
            </div>
            <div class="col-12 col-sm-6 flex-center">
                <div class="pl-60">
                    <h4 class="poppins-bold24 font-weight-bold pt-32 plr-0">Carsome design system</h4>
                    <p class="poppins-regular16  p-0 pb-sm-0 pb-24">
                        More case studies are coming soon! Please stay tuned and it will available soon.
                    </p>
                    <button class="poppins-regular16 color-grey02 bg-black">Coming soon</button>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>